import React, { useState, useEffect } from 'react'
import { readAboutfull } from '../../store/api/frontend'
import BackButton from '../../components/Backbutton'


const Menu2 = () => {
    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await readAboutfull();
                setData(response);
            } catch (error) {
                console.error('Error fetching blog data:', error);
            }
        };
        fetchData();
    }, []);

    return (
        <div>
            {data.map((about) => (
                <div key={about._id}>
                    <div
                        className="relative bg-cover md:bg-center md:h-[330px] h-[350px]"
                        style={{ backgroundImage: `url(${about.imageUrl3})` }}
                    >

                        <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-20">
                            <div className="text-white md:text-center p-9 mt-[-10px]">
                                <div className='md:hidden block'>
                                    <BackButton />

                                </div>
                                <h1 className="text-2xl font-bold text-gradient ">
                                    {about.title2}
                                </h1>
                                <p className="text-[12px] mt-2 font-Athiti max-w-[90ch]">
                                    {about.detail4}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div
                        className="relative bg-cover bg-center md:h-[360px] h-[350px]"
                        style={{ backgroundImage: `url(${about.imageUrl4})` }}
                    >
                        <div className="absolute inset-0 flex items-center md:justify-start justify-center  bg-black bg-opacity-20">
                            <div className="text-white md:text-left text-center p-9 md:mx-[200px]">
                                <h2 className="text-2xl font-bold text-gradient ">
                                    {about.title3}
                                </h2>
                                <p className="text-[12px] mt-2 font-Athiti max-w-[70ch]">
                                    {about.detail5}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div
                        className="relative bg-cover bg-center md:h-[360px] h-[350px]"
                        style={{ backgroundImage: `url(${about.imageUrl5})` }}
                    >
                        <div className="absolute inset-0 flex items-center md:justify-end justify-center  bg-black bg-opacity-20">
                            <div className="text-white md:text-left text-center p-9 md:mx-[200px]">
                                <h3 className="text-2xl font-bold text-gradient ">
                                    {about.title4}
                                </h3>
                                <p className="text-[12px] mt-2 font-Athiti max-w-[70ch]">
                                    {about.detail6}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div
                        className="relative bg-cover bg-center md:h-[330px] h-[350px]"
                        style={{ backgroundImage: `url(${about.imageUrl6})` }}
                    >
                        <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-20">
                            <div className="text-white md:text-center p-9">
                                <h4 className="text-2xl font-bold text-gradient ">
                                    {about.title5}
                                </h4>
                                <p className="text-[12px] mt-2 font-Athiti max-w-[90ch]">
                                    {about.detail7}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            ))}


        </div>
    )
}

export default Menu2
