import { useState, useEffect } from "react"
import { Link, useLocation } from "react-router-dom"
import { styles } from '../page/layout/style';
import { readLink } from "../store/api/frontend";



const NavUpper = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await readLink();
        if(Array.isArray(response.data)) { 
          setData(response.data);
        } else {
          console.error('Response data is not an array:', response.data);
        }
      } catch (error) {
        console.error('Error fetching nav data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <nav
      className={`${styles.paddingX} hidden md:block navbar2 w-full flex items-center lg:h-[60px] h-[50px]  z-20`}
    >
      
        <div className="w-full flex justify-center items-center max-w-7xl mx-auto">

        <ul className="list-non hidden md:flex flex-row gap-5 lg:mt-4 md:mt-3 ">
          {data.map((link, index) => (
            <li
              key={index}
            >
              <Link to={`${link.link1}`} className="font-Kanit2 mx-2 hover:text-[#fcf6c3]">
                {link.menu1}
              </Link>
              <Link to={`${link.link2}`} className="font-Kanit2 mx-2 hover:text-[#fcf6c3]">
                {link.menu2}
              </Link>
              <Link to={`${link.link3}`} className="font-Kanit2 mx-2 hover:text-[#fcf6c3]">
                {link.menu3}
              </Link>
              <Link to={`${link.link4}`} className="font-Kanit2 mx-2 hover:text-[#fcf6c3]">
                {link.menu4}
              </Link>
              <Link to={`${link.link5}`} className="font-Kanit2 mx-2 hover:text-[#fcf6c3]">
                {link.menu5}
              </Link>
              <Link to={`${link.link6}`} className="font-Kanit2 mx-2 hover:text-[#fcf6c3]">
                {link.menu6}
              </Link>
            </li>
          ))}
        </ul>

          <div className="md:hidden flex flex-1 justify-end items-center   ">
            <div className="mx-4">
              <button className="login-mobile w-[100px] h-[35px] rounded-lg text-gray-800
            text-[15px] font-IBM">
                เข้าสู่ระบบ
              </button>
            </div>
          </div>
        </div>
    </nav>
  )
}

export default NavUpper