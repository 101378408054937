import React from 'react';
import { useNavigate } from 'react-router-dom';
import { MdArrowBackIosNew } from "react-icons/md";

const BackButton = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <button onClick={handleGoBack} className='mt-5 '>
      <MdArrowBackIosNew className='text-[20px] text-yellow-400' />
    </button>
  );
};

export default BackButton;
