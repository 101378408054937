import React, { useState, useEffect } from 'react';
import BlogsCard from './BlogsCard';
import { useMediaQuery } from '@react-hook/media-query'
import { readBlog } from '../store/api/blogs';


const BlogList = () => {
  const [blogData, setBlogData] = useState([]);
  const isXlScreen = useMediaQuery('(min-width: 1200px)');
  const defaultVisibleBlogs = isXlScreen ? 4 : 3;
  const [visibleBlogs, setVisibleBlogs] = useState(defaultVisibleBlogs); 


  const handleLoadMore = () => {
    setVisibleBlogs((prevVisibleBlogs) => prevVisibleBlogs + 4);
  };

  const visibleBlogSlice = blogData.slice(0, visibleBlogs);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await readBlog(); 
        setBlogData(response); 
      } catch (error) {
        console.error('Error fetching blog data:', error);
      }
    };

    fetchData();
  }, []); 

  const totalBlogs = blogData.length;  

  return (
    <div>
      <div className='flex justify-center'>
        <h1 className='text-[35px] text-gradient mt-6 font-IBM'>บทความ</h1>
      </div>
      <div className='flex justify-center mt-5'>
        <div className='grid lg:grid-cols-3 xl:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-5 '>
          {visibleBlogSlice.map((blog) => (
            <BlogsCard key={blog._id} blog={blog} />
          ))}
        </div>
      </div>

      {visibleBlogs < totalBlogs && (
        <div className='flex justify-center mt-5'>
          <button
            onClick={handleLoadMore}
            className='text-[#EEDD82] font-IBM cursor-pointer mt-1 mb-5'
          >
            ดูเพิ่มเติม
          </button>
        </div>
      )}
    </div>
  );
};

export default BlogList;
