import React, { useState, useEffect } from 'react'
import { readAboutfull } from '../../store/api/frontend'
import Backbutton from '../../components/Backbutton'


const Menu4 = () => {
    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await readAboutfull();
                setData(response);
            } catch (error) {
                console.error('Error fetching blog data:', error);
            }
        };
        fetchData();
    }, []);

    return (
        <div>
            {data.map((about) => (
                <div key={about._id}>
                    <div
                        className="relative bg-cover bg-center md:h-[380px] h-[450px]"
                        style={{ backgroundImage: `url(${about.imageUrl7})` }}
                    >
                        <div className="absolute inset-0 flex items-center md:justify-start justify-center  bg-black bg-opacity-20">
                            <div className="text-white md:text-left text-center p-9 md:mx-[200px] md:mt-[30px] mt-[-50px]">
                                <div className='flex justify-start mt-[-20px] md:hidden block'>
                                    <Backbutton />
                                </div>
                                <h1 className="md:text-3xl text-[25px] font-bold text-gradient ">
                                    {about.title6}
                                </h1>
                                <p className="text-[14px] mt-4 font-Athiti md:max-w-[50ch] max-w-[70ch]">
                                    {about.detail8}
                                </p>
                            </div>
                        </div>

                    </div>
                    <div className='login-mobile w-full h-[50px]'>
                        <div className='flex justify-center'>
                            <h2 className='mt-3 text-[20px] text-gray-900 font-bold'>
                                3 ขั้นตอนง่ายๆ ในการร่วมสนุก
                            </h2>
                        </div>
                    </div>

                    <div className='flex justify-center md:mt-0 mt-5 mb-5'>
                        <div className='grid md:grid-cols-3 grid-rows-3'>
                            <img src={about.imageUrl8} className='w-[400px] h-[250px]' />
                            <img src={about.imageUrl9} className='w-[400px] h-[250px]' />
                            <img src={about.imageUrl10} className='w-[400px] h-[250px]' />

                        </div>
                    </div>
                </div>
            ))}

        </div>
    )
}

export default Menu4;
