import axios from "axios";
import { URL_API } from "./local";

export const http = axios.create({
    baseURL: window.location.hostname === 'localhost' ? URL_API : URL_API, 
    headers: {
        "Content-Type": 'multipart/form-data',
    }
})

// export const getToken = () => {
//     return localStorage.getItem('token')
// }