import React from 'react';
import { Link } from 'react-router-dom';
import { IoIosArrowForward } from "react-icons/io";


const BlogCard = ({ blog }) => {
 
  return (
    <div className='bg-[#959595] bg-opacity-10 w-[300px] h-[380px] mb-10 '>
      <div className='flex justify-center'>
        <img src={blog.imageUrl} className='w-full h-[220px] rounded-sm' />

      </div>
      <div className='p-2 mx-2'>
        <h1 className='text-gradient text-[20px] font-thin'>
        <div dangerouslySetInnerHTML={{
            __html:
              blog.title.substr(0, 30)
                .concat(blog.title.length > 36 ? '...' : '')
          }}

          />
          
        </h1>
        <div className='text-[#dfdfdf] mt-[-10px]  font-sans'>

          <div dangerouslySetInnerHTML={{
            __html:
              blog.detail.substr(0, 33)
                .concat(blog.detail.length > 50 ? '...' : '')
          }}

          />
        </div>

        <Link to={`/blog/${blog.tag}`}>
          <p className='text-[#EEDD82] font-Kanit2 cursor-pointer mt-1 font-mitr'>
            อ่านต่อ
            <IoIosArrowForward className='mx-[45px] mt-[-19px]' />
          </p>
        </Link>
      </div>
    </div>
  );
}

export default BlogCard;
