import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { readNews } from '../../store/api/frontend.js';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const News = () => {
    const [newsData, setnewsData] = useState([]);
    

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await readNews();
                setnewsData(response);
            } catch (error) {
                console.error('Error fetching blog data:', error);
            }
        };
        fetchData();
    }, []);

    const settings = {
        infinite: true,
        speed: 500,
        slidesToScroll: 1,
        autoplay: false,
        arrows: false,
        slidesToShow: 4,
        };

    return (
        <div>
            <div className='w-full bg-[#090909f8] lg:h-[180px] md:h-[130px] h-[90px]'>
                <div className='xl:w-[calc(100%/1.5)] lg:w-[calc(100%/1.1)] 
                md:w-[calc(100%/1.3)] w-[calc(100%/1)] justify-center md:mx-auto grid-cols-4 mx-2 my-[-6px]'>
                    <Slider {...settings}>
                    {newsData.map((news, index) => (
                        <a href={`/news/${news.tag}`} key={news._id} className={`${index > 4 ? 'hidden block' : ''}`}>
                            <div className='lg:w-[150px] lg:h-[100px] md:w-[120px] md:h-[70px] w-[92px] h-[60px] lg:mt-6 md:mt-[12px] mt-1 cursor-pointer'>
                                <img src={news.imageUrl} alt={news.title} className="w-full h-full object-cover" />
                            </div>
                            <div>
                            <h1 className={`text-[8px] text-white md:mt-2  mt-[-1px] mx-1 ${news.title1.length > 0 ? 'truncate' : ''}`}>
                                {news.title1}
                            </h1>
                            </div>
                        </a>
                    ))}

                    </Slider>
                </div>
            </div>
        </div>
    );
};

export default News;
