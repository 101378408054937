import React, { useEffect, useState } from 'react';
import { readLink } from '../store/api/frontend'
import Line from '../assets/LINE.svg'

const Contact = () => {
  const [isVisible, setIsVisible] = useState(false)
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await readLink();
        if (Array.isArray(response.data)) {
          setData(response.data);
        } else {
          console.error('Response data is not an array:', response.data);
        }
      } catch (error) {
        console.error('Error fetching nav data:', error);
      }
    };

    fetchData();
  }, []);

  const toggleVisibility = () => {
    setIsVisible(true)
  }

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility)

    return () => {
      window.removeEventListener('scroll', toggleVisibility)
    }
  }, [])

  const classNames = (...classes) => {
    return classes.filter(Boolean).join(' ')
  }
  return (
    <div>
      {data.map((link) => 
      <div key={link._id} className="fixed top-[250px] right-2">
        <a  href={`http://${link.line}`} target="_blank" rel="noopener noreferrer">

          <button
            type="button"
            className={classNames(
              isVisible ? 'opacity-100' : 'opacity-0',
              '   items-center rounded-full lg:w-[90px] lg:h-[90p] w-[70px] h-[70px] p-3 text-white shadow-sm transition-opacity ',
            )}
          >
            <img src={Line} className='w-full   border-yellow-500 border-solid border-2 rounded-full' />
          </button>
        </a>
      </div>
      )}
    </div>
  )
}

export default Contact
