import { http } from '../../utils';
import { URL_API } from '../../utils/local'


export const readHeader = async () => {
  try {
    const response = await http.get('/fixfrontend/get/header');
    const data = response.data.map((fix) => ({
      ...fix,
      imageUrl: fix.file ? `${URL_API}/${fix.file.replace('image/', '')}` : null,
    }));

    return data;
    
  } catch (error) {
    console.log('api error', error);
    throw error;
  }
}

export const readGame = async () => {
  try {
    const response = await http.get('/fixfrontend/get/about');
    const data = response.data.map((fix) => ({
      ...fix,
      imageUrl: fix.file ? `${URL_API}/${fix.file.replace('image/', '')}` : null,
      imageUrl1: fix.file1 ? `${URL_API}/${fix.file1.replace('image/', '')}` : null,
      imageUrl2: fix.file2 ? `${URL_API}/${fix.file2.replace('image/', '')}` : null,
      imageUrl3: fix.file3 ? `${URL_API}/${fix.file3.replace('image/', '')}` : null,
      imageUrl4: fix.file4 ? `${URL_API}/${fix.file4.replace('image/', '')}` : null,
      imageUrl5: fix.file5 ? `${URL_API}/${fix.file5.replace('image/', '')}` : null,
      imageUrl6: fix.file6 ? `${URL_API}/${fix.file6.replace('image/', '')}` : null,
      imageUrl7: fix.file7 ? `${URL_API}/${fix.file7.replace('image/', '')}` : null,
    }));

    return data;

  } catch (error) {
    console.log('api error', error)
    throw error;
  }
};

export const readDetail = async () => {
    try {
      const response = await http.get('/fixfrontend/get/detail');
      const data = response.data.map((fix) => ({
        ...fix,
        imageUrl: fix.file ? `${URL_API}/${fix.file.replace('image/', '')}` : null,
        imageUrl1: fix.file1 ? `${URL_API}/${fix.file1.replace('image/', '')}` : null,
        imageUrl2: fix.file2 ? `${URL_API}/${fix.file2.replace('image/', '')}` : null,
        imageUrl3: fix.file3 ? `${URL_API}/${fix.file3.replace('image/', '')}` : null,
        imageUrl4: fix.file4 ? `${URL_API}/${fix.file4.replace('image/', '')}` : null,
      }));

      return data;
  
    } catch (error) {
      console.log('api error', error)
      throw error;
    }
  };

  export const readDetail2 = async () => {
    try {
      const response = await http.get('/fixfrontend/get/detail2');
      const data = response.data.map((fix) => ({
        ...fix,
        imageUrl: fix.file ? `${URL_API}/${fix.file.replace('image/', '')}` : null,
      }));

      return data;
  
    } catch (error) {
      console.log('api error', error)
      throw error;
    }
  };

  export const readAboutfull = async () => {
    try {
      const response = await http.get('/fixfrontend/get/aboutfull');
      const data = response.data.map((fix) => ({
        ...fix,
        imageUrl: fix.file ? `${URL_API}/${fix.file.replace('image/', '')}` : null,
        imageUrl1: fix.file1 ? `${URL_API}/${fix.file1.replace('image/', '')}` : null,
        imageUrl2: fix.file2 ? `${URL_API}/${fix.file2.replace('image/', '')}` : null,
        imageUrl3: fix.file3 ? `${URL_API}/${fix.file3.replace('image/', '')}` : null,
        imageUrl4: fix.file4 ? `${URL_API}/${fix.file4.replace('image/', '')}` : null,
        imageUrl5: fix.file5 ? `${URL_API}/${fix.file5.replace('image/', '')}` : null,
        imageUrl6: fix.file6 ? `${URL_API}/${fix.file6.replace('image/', '')}` : null,
        imageUrl7: fix.file7 ? `${URL_API}/${fix.file7.replace('image/', '')}` : null,
        imageUrl8: fix.file8 ? `${URL_API}/${fix.file8.replace('image/', '')}` : null,
        imageUrl9: fix.file9 ? `${URL_API}/${fix.file9.replace('image/', '')}` : null,
        imageUrl10: fix.file10 ? `${URL_API}/${fix.file10.replace('image/', '')}` : null,
        imageUrl11: fix.file11 ? `${URL_API}/${fix.file11.replace('image/', '')}` : null,
        imageUrl12: fix.file12 ? `${URL_API}/${fix.file12.replace('image/', '')}` : null,
        imageUrl13: fix.file13 ? `${URL_API}/${fix.file13.replace('image/', '')}` : null,
        imageUrl14: fix.file14 ? `${URL_API}/${fix.file14.replace('image/', '')}` : null,
      }));
  
      return data;
  
    } catch (error) {
      console.log('api error', error)
      throw error;
    }
  };

  export const readAboutus = async () => {
    try {
      const response = await http.get('/fixfrontend/get/aboutus');
      const data = response.data.map((fix) => ({
        ...fix,
        imageUrl: fix.file ? `${URL_API}/${fix.file.replace('image/', '')}` : null,
      }));
  
      return data;
  
    } catch (error) {
      console.log('api error', error);
      throw error;
    }
  }

  export const readTitle = async () => {
    try {
      const response = await http.get('/fixfrontend/get/title');
      const data = response.data.map((fix) => ({
        ...fix,
        imageUrl: fix.file ? `${URL_API}/${fix.file.replace('image/', '')}` : null,
      }));
  
      return data;
      
    } catch (error) {
      console.log('api error', error);
      throw error;
    }
  }

  export const readNews = async () => {
    try {
      const response = await http.get('/fixfrontend/get/news');
      const data = response.data.map((fix) => ({
        ...fix,
        imageUrl: fix.file ? `${URL_API}/${fix.file.replace('image/', '')}` : null,
      }));
  
      return data;
      
    } catch (error) {
      console.log('api error', error);
      throw error;
    }
  }



  export const readNav = async () => {
    try {
      const response = await http.get('/fixfrontend/get/title');
      const data = response.data.map((fix) => ({
        ...fix,
        imageUrl: fix.file ? `${URL_API}/${fix.file.replace('image/', '')}` : null,
      }));
  
      return data;
      
    } catch (error) {
      console.log('api error', error);
      throw error;
    }
  }


  export const readLink = async () => {
    return await http.get('/fixfrontend/get/link');
  };

  

