import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { readBlog } from '../../store/api/blogs';
import BackButton from '../../components/Backbutton';
import '../../utils/style.css'


const Index = () => {
  const { tag } = useParams();
  const [blogData, setBlogData] = useState([]);
  const selectedBlog = blogData.find(blog => blog.tag === tag);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await readBlog();
        setBlogData(response);
      } catch (error) {
        console.error('Error fetching blog data:', error);
      }
    };

    fetchData();
  }, [tag]);

  useEffect(() => {
    if (selectedBlog) {
      const navbarHeight = 60;

      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }, [selectedBlog]);


  if (!selectedBlog) {
    return (
      <div className='bg-[#131313f8] h-screen  '>
        <div className='flex justify-center'>
          <div className='p-5 xl:w-[1100px] lg:w-[900px] md:w-[800px] sm:w-[600px] w-[450px]'>
            <h1 className='mt-4 text-white text-[27px]'>Blog Not Found</h1>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className='bg-[#131313f8]  '>
      <div className='flex justify-center '>
        <div className='p-5 xl:w-[1100px] lg:w-[900px] md:w-[800px] sm:w-[600px] w-[450px]  '>
          <div className='mx-5'>
           <BackButton />
          </div>
          <h1 className='mt-4 text-gradient font-IBM lg:text-[35px] text-[27px] mx-5 '>
            {selectedBlog.title}
          </h1>

          <div className='w-full h-[0.04px] bg-[#c3c3c3] mt-4' />

          <div className='md:mt-10 mt-7 flex justify-center'>
            <img
              src={selectedBlog.imageUrl}
              alt="Blog Image"
              className='xl:w-[850px] lg:w-[700px] md:w-[600px] xl:h-[450px] lg:h-[350px] md:h-[300px] 
              w-[350px] h-[250px] object-cover' 
            />
          </div>

         
            <div className='md:mt-10 mt-5 mx-7 ' 
            dangerouslySetInnerHTML={{ __html: selectedBlog.detail }}
             />
         
          {/* <div className='md:mt-10 mt-5 md:mx-[40px]'>
            <h2 className='mt-4 text-[#EEDD82] font-IBM lg:text-[35px] text-[27px]'>
              {selectedBlog.title2}
            </h2>
          </div>
          <p className='text-white mt-2 lg:pl-[30px] md:mx-[50px] font-Athiti'>
            {selectedBlog.detail2}
          </p>
          <div className='md:mt-10 mt-5 md:mx-[40px]'>
            <h3 className='mt-4 text-[#EEDD82] font-IBM lg:text-[35px] text-[27px]'>
              {selectedBlog.title3}
            </h3>
          </div>
          <p className='text-white mt-2 lg:pl-[30px] md:mx-[50px] font-Athiti'>
            {selectedBlog.detail3}
            <a href={'https://google.com'} target="_blank" rel="noopener noreferrer"
              className='text-[#c14ff1] ml-1'>
               linklink
            </a>
            เป็นโปรโมชั่นที่ยอดฮิตและบอกได้คำเดียวเลยว่าคุ้มค่าแน่นอน และคุณยังสามารถเข้ามาซื้อฟรีสปินในราคาถูก ที่เรานั้นมีจำหน่ายไว้ให้คุณได้เข้ามาเล่น และพบกับข้อเสนอดีๆอีกมากมายภายในเว็บของเรา และนอกจากนี้เรายังมีฟรีสปินให้คุณได้เข้ามาร่วมเล่นเกมสล็อตกับทางเว็บ 123goal ของเราได้ทุกเมื่อและเข้ามากดรับฟรีสปินได้ง่ายแบบฟรีๆ
          </p>
          <div className='md:mt-10 mt-5 md:mx-[40px]'>
            <h4 className='mt-4 text-[#EEDD82] font-IBM lg:text-[35px] text-[27px]'>
              {selectedBlog.title4}
            </h4>
          </div>
          <p className='text-white lg:pl-[30px] md:mx-[50px] font-Athiti'>
            {selectedBlog.detail4}
          </p>
          <div className='md:mt-10 mt-5 md:mx-[40px]'>
            <h5 className='mt-4 text-[#EEDD82] font-IBM lg:text-[35px] text-[27px]'>
              {selectedBlog.title5}
            </h5>
          </div>
          <p className='text-white mt-2 lg:pl-[30px] md:mx-[50px] font-Athiti'>
            {selectedBlog.detail5}
          </p> */}
        </div>


      </div>

    </div>
  );
}

export default Index;
