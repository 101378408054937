import React, { useState, useEffect } from 'react';
import { readGame, readMobile } from '../../store/api/frontend'
import { Link } from 'react-router-dom';


const Gamecard = () => {
    const [selectedMenu, setSelectedMenu] = useState('link');
    const [GameData, setGameData] = useState([]);

    const handleMenuClick = (menu) => {
        setSelectedMenu(menu);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await readGame();
                setGameData(response);
            } catch (error) {
                console.error('Error fetching blog data:', error);
            }
        };

        fetchData();
    }, []);


    return (
        <div>
            {GameData.map((game) => (
                <div key={game._id}>
                    <div className='md:hidden block'>
                        <div className='w-full bg-[#090909f8] h-[80px]'>
                            <div className='grid grid-cols-4 gap-2 sm:mx-6 mx-4 my-[-6px]'>
                                <button
                                    className={`game-mobile transform -skew-x-12 cursor-pointer hover:bg-gray-900 ${selectedMenu === 'link' && 'bg-gray-900' // เพิ่มสีพื้นหลังเมื่อถูกเลือก
                                        }`}
                                    onClick={() => handleMenuClick('link')}
                                >
                                    <div className='flex justify-center mt-[10px]'>
                                        <img src={game.imageUrl4} className='w-[50px] h-[50px]' />
                                    </div>
                                    <div className='flex justify-center mt-[-15px]'>
                                        <h1 className='text-[12px] font-IBM text-[#242424]'>
                                            {game.menu1}
                                        </h1>
                                    </div>
                                </button>

                                <button
                                    className={`game-mobile transform -skew-x-12 cursor-pointer hover:bg-gray-900 ${selectedMenu === 'link' && 'bg-gray-900' // เพิ่มสีพื้นหลังเมื่อถูกเลือก
                                        }`}
                                    onClick={() => handleMenuClick('sport')}
                                >
                                    <div className='flex justify-center mt-[10px]'>
                                        <img src={game.imageUrl5} className='w-[50px] h-[50px]' />
                                    </div>
                                    <div className='flex justify-center mt-[-15px]'>
                                        <h1 className='text-[13px] font-IBM text-[#242424]'>
                                            {game.menu2}
                                        </h1>
                                    </div>
                                </button>
                                <button
                                    className={`game-mobile transform -skew-x-12 cursor-pointer hover:bg-gray-900 ${selectedMenu === 'link' && 'bg-gray-900' // เพิ่มสีพื้นหลังเมื่อถูกเลือก
                                        }`}
                                    onClick={() => handleMenuClick('casino')}
                                >
                                    <div className='flex justify-center mt-[10px]'>
                                        <img src={game.imageUrl6} className='w-[50px] h-[50px]' />
                                    </div>
                                    <div className='flex justify-center mt-[-15px]'>
                                        <h1 className='text-[13px] font-IBM text-[#242424]'>
                                            {game.menu3}
                                        </h1>
                                    </div>
                                </button>
                                <button
                                    className={`game-mobile transform -skew-x-12 cursor-pointer hover:bg-gray-900 ${selectedMenu === 'link' && 'bg-gray-900' // เพิ่มสีพื้นหลังเมื่อถูกเลือก
                                        }`}
                                    onClick={() => handleMenuClick('slot')}
                                >
                                    <div className='flex justify-center mt-[10px]'>
                                        <img src={game.imageUrl7} className='w-[50px] h-[50px]' />
                                    </div>
                                    <div className='flex justify-center mt-[-15px]'>
                                        <h1 className='text-[13px] font-IBM text-[#242424]'>
                                            {game.menu4}
                                        </h1>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className='w-full bg-[#181e2e] h-[200px] md:hidden block'>
                        {selectedMenu === 'link' && (
                            <div>
                                <div className='flex justify-center'>
                                    <h1 className='text-[#EEDD82] mt-5 text-[20px] font-IBM'>
                                        {game.title}
                                    </h1>
                                </div>
                                <div className='flex justify-center mt-[-15px]'>
                                    <h2 className='text-white text-[15px] font-IBM'>
                                        {game.subtitle}
                                    </h2>
                                </div>
                                <div className='flex justify-center mt-[-15px] mx-[40px]'>
                                    <h2 className='text-white text-[12px] text-center mx-auto font-IBM'>
                                        {game.detail}
                                    </h2>
                                </div>
                                <div className='flex justify-center mt-1 font-IBM '>
                                <Link to={`/menu1/${game.link1}`}>
                                    <button className='text-[12px] text-[#f8de7e]'
                                        >
                                        อ่านเพิ่มเติม
                                    </button>
                                    </Link>
                                </div>

                            </div>
                        )}
                        {selectedMenu === 'sport' && (
                            <div>
                                <div className='flex justify-center'>
                                    <h1 className='text-[#EEDD82] mt-5 text-[20px] font-IBM'>
                                        {game.title2}
                                    </h1>
                                </div>
                                <div className='flex justify-center mt-[-15px]'>
                                    <h2 className='text-white text-[15px] font-IBM'>
                                        {game.subtitle2}
                                    </h2>
                                </div>
                                <div className='flex justify-center mt-[-15px] mx-[40px]'>
                                    <h2 className='text-white text-[12px] text-center mx-auto font-IBM'>
                                        {game.detail2}
                                    </h2>
                                </div>
                                <div className='flex justify-center mt-1 font-IBM '>
                                <Link to={`/menu2/${game.link2}`}>
                                    <button className='text-[12px] text-[#f8de7e]'
                                    >
                                        อ่านเพิ่มเติม
                                    </button>
                                    </Link>
                                </div>

                            </div>
                        )}
                        {selectedMenu === 'casino' && (
                            <div>
                                <div className='flex justify-center'>
                                    <h1 className='text-[#EEDD82] mt-5 text-[20px] font-IBM'>
                                        {game.title3}
                                    </h1>
                                </div>
                                <div className='flex justify-center mt-[-15px]'>
                                    <h2 className='text-white text-[15px] font-IBM'>
                                        {game.subtitle3}
                                    </h2>
                                </div>
                                <div className='flex justify-center mt-[-15px] mx-[40px]'>
                                    <h2 className='text-white text-[12px] text-center mx-auto font-IBM'>
                                        {game.detail3}
                                    </h2>
                                </div>
                                <div className='flex justify-center mt-1 font-IBM '>
                                <Link to={`/menu3/${game.link3}`}>
                                    <button className='text-[12px] text-[#f8de7e]'
                                    >
                                        อ่านเพิ่มเติม
                                    </button>
                                    </Link>
                                </div>

                            </div>
                        )}
                        {selectedMenu === 'slot' && (
                            <div>
                                <div className='flex justify-center'>
                                    <h1 className='text-[#EEDD82] mt-5 text-[20px] font-IBM'>
                                        {game.title4}
                                    </h1>
                                </div>
                                <div className='flex justify-center mt-[-15px]'>
                                    <h2 className='text-white text-[15px] font-IBM'>
                                        {game.subtitle4}
                                    </h2>
                                </div>
                                <div className='flex justify-center mt-[-15px] mx-[40px]'>
                                    <h2 className='text-white text-[12px] text-center mx-auto font-IBM'>
                                        {game.detail4}
                                    </h2>
                                </div>
                                <div className='flex justify-center mt-1 font-IBM '>
                                <Link to={`/menu4/${game.link4}`}>
                                    <button className='text-[12px] text-[#f8de7e]'
                                    >
                                        อ่านเพิ่มเติม
                                    </button>
                                    </Link>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            ))}
        </div>
    )
}

export default Gamecard
