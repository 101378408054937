import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { readHeader } from '../store/api/frontend';


const Header = () => {
  const [headerData, setHeaderData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await readHeader();
        setHeaderData(response);
      } catch (error) {
        console.error('Error fetching header data:', error);
      }
    };

    fetchData();
  }, []);

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
  };

  return (
    <div className="flex justify-center  ">
      <div className="w-full">
        <Slider {...settings}>
        {headerData.map((item) => (
            <div key={item._id}>
              <img
                src={item.imageUrl}  
                alt={`Slide `}
                className="w-full xl:h-[600px] lg:h-[450px] md:h-[300px] sm:h-[250px] h-[200px] object-cover"
              />
            </div>
          ))}

        {headerData.map((item) => (
            <div key={item._id}>
              <img
                src={item.imageUrl}  
                alt={`Slide `}
                className="w-full xl:h-[600px] lg:h-[450px] md:h-[300px] h-[200px] object-cover"
              />
            </div>
          ))}
        </Slider>

      </div>
    </div>
  );
};

export default Header;