import React, { useState, useEffect } from 'react'
import { readDetail } from '../../store/api/frontend'
import Backbutton from '../../components/Backbutton'

const Menu1 = () => {
    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await readDetail();
                console.log(response)
                setData(response);
            } catch (error) {
                console.error('Error fetching blog data:', error);
            }
        };

        fetchData();
    }, []);
    return (
        <div>
            {data.map((about) => (
            <div className='p-[40px] lg:mx-[100px]'>
                <div className='mt-10'>
                    <div className='md:hidden block'>
                        <Backbutton />
                    </div>
                    <h1 className='text-gradient  md:text-[35px] text-[28px] font-bold'>
                        {about.title1}
                    </h1>
                </div>

                <div className='sm:w-[500px] h-[1px] w-[300px]  bg-white md:mt-[20px] mt-[25px]' />

                <div className='mt-[20px]'>
                    <img src={about.imageUrl1} className='md:w-[750px] md:h-[290px] w-[400px] h-[150px] ' />
                </div>

                <div className='mt-5'>
                    <p className='font-Athiti'>{about.detail1}</p>
                </div>

                <div className='sm:w-[500px] h-[1px] w-[300px] bg-white mt-[20px]' />

                <div className='mt-[20px]'>
                    <img src={about.imageUrl2} className='md:w-[750px] md:h-[280px] w-[400px] h-[160px] ' />
                </div>

                <div className='mt-[30px]'>
                    <p className='font-Athiti'>
                        {about.detail2}
                    </p>
                </div>

                <div className='mt-[20px]'>
                    <img src={about.imageUrl3} className='md:w-[750px] md:h-[370px] w-[400px] h-[250px] ' />
                </div>

                <div className='mt-[30px]'>
                    <h2 className='text-gradient  sm:text-[30px] text-[25px] font-IBM'>
                    {about.title2}
                    </h2>
                    <p className='font-Athiti'>
                    {about.detail3}
                    </p>
                    <p className='mt-5'>
                    {about.detail4}
                    </p>
                </div>

                <div className='mt-[30px]'>
                    <h4 className='text-gradient  sm:text-[30px] text-[25px]'>
                    {about.title3}
                    </h4>
                    <p className='font-Athiti'>
                    {about.detail5}
                    </p>
                    <p className='mt-5 font-Athiti'>
                    {about.detail6}
                    </p>
                </div>

                <div className='mt-[30px]'>
                    <h5 className='text-gradient  sm:text-[30px] text-[25px]'>
                        {about.title4}
                    </h5>
                    <p className='font-Athiti mt-2'>
                    {about.detail7}
                    </p>
                    <p className='mt-5 font-Athiti'>
                    {about.detail8}
                    </p>
                </div>

                <div className='sm:w-[500px] md:w-[740px] h-[1px] w-[text-gradient ] bg-white mt-[20px]' />
            </div>
            ))}
        </div>
    )
}

export default Menu1
