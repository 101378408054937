import React, { useState, useEffect } from 'react';
import BlogCard from '../components/BlogsCard';
import { readBlog } from '../store/api/blogs';

const Blog = () => {
    const [data, setData] = useState([])

    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await readBlog(); 
            setData(response); 
          } catch (error) {
            console.error('Error fetching blog data:', error);
          }
        };
    
        fetchData();
      }, []); 
  return (
    <div>
    <div className='flex justify-center'>
      <h1 className='text-[35px] text-gradient mt-6 font-IBM'>บทความ</h1>
    </div>
    <div className='flex justify-center mt-5'>
      <div className='grid lg:grid-cols-3 xl:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-5 '>
        {data.map((blog) => (
          <BlogCard key={blog._id} blog={blog} />
        ))}
      </div>
    </div>

  </div>
  )
}

export default Blog
