import React, { useState, useEffect } from 'react'
import Header from '../components/Header'
import BlogList from '../components/BlogList'
import Gamecard from '../components/gameMenu/Gamecard'
import GamecardMobile from '../components/gameMenu/Gamecardmobile'
import Contact from '../components/Contact'
import News from '../components/news/News'
import { Link } from 'react-router-dom';

import { readDetail, readDetail2, readTitle } from '../store/api/frontend'
import { Helmet, HelmetProvider } from 'react-helmet-async';


const Home = () => {
    const [DetailData, setDetailData] = useState([]);
    const [DetailData2, setDetailData2] = useState([]);
    const [ReadTitle, setReadTitle] = useState([]);

    // Get Detail
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await readDetail();
                setDetailData(response);
            } catch (error) {
                console.error('Error fetching blog data:', error);
            }
        };
        fetchData();
    }, []);

    // Get Detail2
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await readDetail2();
                setDetailData2(response);
            } catch (error) {
                console.error('Error fetching blog data:', error);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await readTitle();
                setReadTitle(response);
            } catch (error) {
                console.error('Error fetching blog data:', error);
            }
        };
        fetchData();
    }, []);

    return (
        <div className='bg-[#0e0e0e]'>
            {ReadTitle.map((title) => (
                <HelmetProvider key={title._id}>
                <Helmet>
                    <title>{title.name1}</title>
                    <meta name={title.detail1} keyword={title.keyword} />
                    <meta name={title.name13} content={title.detail13} />
                    <meta name={title.name2} content={title.detail2} />
                    <meta name={title.name3} content={title.detail3} />
                    <meta name={title.name4} content={title.detail4} />
                    <meta name={title.name5} content={title.detail5} />
                    <meta name={title.name6} content={title.detail6} />
                    <meta name={title.name7} content={title.detail7} />
                    <meta name={title.name8} content={title.detail8} />
                    <meta name={title.name9} content={title.detail9} />
                    <meta name={title.name10} content={title.detail10} />
                    <meta name={title.name11} content={title.detail11} />
                    <meta name={title.name12} content={title.detail12} />
                </Helmet>
                </HelmetProvider>
            ))}
            <Header />
            <News />
            <Gamecard />
            <GamecardMobile />
            {/* Detail Section  */}
            <div>
                {DetailData.map((detail) => (
                    <div key={detail._id} className='gridBody md:gridBody-cols-2 '>
                        <div>
                            <img src={detail.imageUrl} className='bg-black w-full xl:h-[420px] lg:h-[380px]  h-[250px]' />
                        </div>
                        <div className='bg-gradient-to-l from-[#1a1a29]  to-[#090d18] w-full xl:h-[420px] lg:h-[350px] md:h-[250px]
                        order-first md:order-last'>
                            <div className='p-5 lg:mt-[10px] mt-[-10px]'>
                                <div className='flex justify-start mx-5'>
                                    <h1 className='text-[#EEDD82] xl:text-[40px] lg:text-[30px] text-[25px] font-IBM'>
                                        {detail.title}
                                    </h1>
                                </div>
                                <div className='flex justify-start lg:mt-[-10px] md:mt-[-10px] mt-[-15px] mx-5'>
                                    <h2 className='text-white xl:text-[16px] lg:text-[14px] text-[12px] font-IBM
                                break-words lg:max-w-[60ch] md:max-w-[50ch]'>
                                        {detail.subdetail}
                                    </h2>
                                </div>
                                <div className='flex justify-start lg:mt-5 mt-2 mx-5'>
                                    <p className='text-[#C0C0C0] xl:text-[16px] lg:text-[12px] text-[10px] font-IBM
                                break-words lg:max-w-[60ch]'>
                                        {detail.detail}
                                    </p>
                                </div>
                                <div className='flex justify-start xl:mt-4 mx-5'>
                                    <Link to={`/menu1/detail/${detail.link}`}>
                                        <button className='text-[#EEDD82] md:text-[14px] text-[12px] mt-4 font-IBM cursor-pointer'>
                                            อ่านเพิ่มเติม
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            {/* Detail2 Section  */}
            <div>
                {DetailData2.map((detail) => (
                    <div key={detail._id} className='gridBody2 md:gridBody2-cols-2 grid-cols-1'>
                        <div className='bg-gradient-to-r from-[#1a1a29]  to-[#090d18] w-full xl:h-[400px]  lg:h-[380px] h-[250px]'>
                            <div className='p-5'>
                                <div className='flex justify-end lg:mt-4 md:mt-[-20px]  mx-5'>
                                    <h1 className='text-[#EEDD82] lg:text-[35px] text-[25px] font-IBM text-right'>
                                        <div dangerouslySetInnerHTML={{
                                        __html:
                                        detail.title.substr(0, 50)
                                            .concat(detail.title.length > 36 ? '...' : '')
                                        }}
                                    />
                                    </h1>
                                </div>
                                <div className='flex justify-end mt-[-15px] mx-5'>
                                    <h2 className='text-white lg:text-[25px] text-[15px] font-IBM'>
                                        <div dangerouslySetInnerHTML={{
                                        __html:
                                        detail.detail.substr(0, 50)
                                            .concat(detail.detail.length > 50 ? '...' : '')
                                        }}
                                    />
                                    </h2>
                                </div>
                                <div className='flex justify-end mt-1 mx-5'>
                                    <p className='text-[#faf2f2dd] lg:text-[14px] text-[10px] font-IBM
                                lg:max-w-[35ch] md:max-w-[30ch] text-right'>
                                        {detail.subdetail}
                                    </p>
                                </div>

                                <div className='flex justify-end mt-6 mx-5'>
                                    <a href={`http://${detail.link}`} target="_blank">
                                        <button className='bg-[#e8d558] text-gray-800 
                                        w-[150px] h-[35px] rounded-lg text-[14px] font-IBM 
                                        lg:w-[200px] lg:h-[50px] lg:text-[18px] hover:bg-[#ffec74]'
                                        >
                                            กดเพื่อสมัครรับลิงค์
                                        </button>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div>
                            <img src={detail.imageUrl} className='w-full xl:h-[400px] lg:h-[350px]  h-[250px] ' />
                        </div>
                    </div>
                ))}
            </div>

            {/* Blos Section */}
            <BlogList />
            <Contact />


        </div>
    )
}

export default Home
