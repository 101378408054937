import React, { useEffect, useState } from 'react';
import { readLink } from '../store/api/frontend'
import { Link, useNavigate } from 'react-router-dom';


const Bottom = () => {
    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await readLink();
                if (Array.isArray(response.data)) {
                    setData(response.data);
                } else {
                    console.error('Response data is not an array:', response.data);
                }
            } catch (error) {
                console.error('Error fetching nav data:', error);
            }
        };

        fetchData();
    }, []);


    return (
        <section>
            <div className="fixed bottom-0 left-0 z-50 w-full md:hidden block ">
                {data.map((link) =>
                    <div key={link._id} className="grid h-full max-w-lg grid-cols-2 mx-auto font-medium md:gap-4">
                        <Link to={link.login}>
                            <button className='login-mobile w-full h-[60px] rounded-sm'>
                                <div className='flex justify-center'>
                                    <h1 className='text-black text-[18px] font-IBM'>
                                        เข้าสู่ระบบ
                                    </h1>
                                </div>
                            </button>
                        </Link>

                        <Link to={link.login}>
                            <button className='bottom-mobile w-full h-[60px] rounded-sm'>
                                <div className='flex justify-center'>
                                    <h1 className='text-black text-[18px] font-IBM'>
                                        สมัครสมาชิก
                                    </h1>
                                </div>
                            </button>
                        </Link>
                    </div>
                )}
            </div>
        </section>
    );
};

export default Bottom;
