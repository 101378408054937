import React, {useState, useEffect} from 'react';
import { Outlet } from 'react-router-dom';
import NavUpper from '../../components/NavUpper';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import Bottom from '../../components/Bottom';


const Layout = () => {
  
  return (
    <div>
      <Navbar />
      <NavUpper />
      <Outlet />
      <Bottom />
      <Footer />
    </div>
  );
}

export default Layout;
