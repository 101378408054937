import React, { useState, useEffect } from 'react'
import { readAboutus } from '../store/api/frontend'
import BackButton from '../components/Backbutton'


const AboutUs = () => {
    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await readAboutus();
                setData(response);
            } catch (error) {
                console.error('Error fetching blog data:', error);
            }
        };

        fetchData();
    }, []);
    return (
        <div>

            {data.map((about) => (
                <div className='mb-10 p-6 md:mx-[160px] mx-0'>
                    <div className=''>
                        <BackButton />
                        <h1 className='text-gradient font-bold text-[35px]'>{about.title1}</h1>
                    </div>

                    <div className='md:mt-[10px]  mt-[20px]'>
                        <p className='text-white text-[18px] font-Mitr md:max-w-[60ch]'>
                            {about.detail1}
                        </p>
                    </div>

                    <div className='mt-[15px]'>
                        <p className='text-white text-[18px] font-Mitr md:max-w-[60ch]'>
                            {about.detail2}
                        </p>
                    </div>

                    <div className='md:mt-[90px] mt-[70px]'>
                        <h1 className='text-gradient font-bold text-[35px]'>{about.title2}</h1>
                    </div>

                    <div
                        className="relative bg-cover bg-center md:h-[550px] h-[350px] mt-4"
                        style={{ backgroundImage: `url(${about.imageUrl})` }}
                    />
                </div>
            ))}
        </div>
    )
}

export default AboutUs;
