import React, { useState, useEffect } from 'react'
import { readAboutfull } from '../../store/api/frontend'
import Backbutton from '../../components/Backbutton'


const Menu = () => {
    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await readAboutfull();
                setData(response);
            } catch (error) {
                console.error('Error fetching blog data:', error);
            }
        };

        fetchData();
    }, []);

    return (
        <div>
            {data.map((about) => (
                <div key={about._id} className='p-[40px] lg:mx-[100px]'>
                    <div className=''>
                        <div className='flex justify-start mt-[-30px]  md:hidden block'>
                            <Backbutton />
                        </div>

                        <h1 className='text-gradient  md:text-[35px] text-[28px] font-bold'>
                            {about.title}
                        </h1>
                    </div>

                    <div className='sm:w-[500px] h-[1px] w-[300px]  bg-white md:mt-[20px] mt-[25px]' />

                    <div className='mt-[20px]'>
                        <img src={about.imageUrl} className='md:w-[750px] md:h-[290px] w-[450px] h-[190px] ' />
                    </div>

                    <div className='mt-5'>
                        <p className='font-Athiti'>{about.detail}</p>
                    </div>

                    <div className='sm:w-[500px] h-[1px] w-[300px] bg-white mt-[20px]' />

                    <div className='mt-[20px]'>
                        <img src={about.imageUrl1} className='md:w-[750px] md:h-[400px] w-[400px] h-[200px] ' />
                    </div>

                    <div className='mt-[30px]'>
                        <p className='font-Athiti md:max-w-[90ch]'>
                            {about.detail1}
                        </p>
                    </div>

                    <div className='mt-[20px]'>
                        <img src={about.imageUrl2} className='md:w-[750px] md:h-[500px] w-[400px] h-[300px] ' />
                    </div>

                    <div className='mt-[30px]'>
                        <h2 className='text-gradient  sm:text-[30px] text-[25px] font-IBM'>
                            {about.title1}
                        </h2>
                        <p className='font-Athiti md:max-w-[90ch]'>
                            {about.detail2}
                        </p>
                        <p className='mt-5 md:max-w-[90ch]'>
                            {about.detail3}
                        </p>
                    </div>

                    <div className='sm:w-[500px] md:w-[740px] h-[1px] w-[text-gradient ] bg-white mt-[20px]' />
                </div>
            ))}
        </div>


    )
}

export default Menu
