import React, { useState, useEffect } from 'react'
import { readNews } from '../../store/api/frontend.js'
import { useParams } from 'react-router-dom';
import BackButton from '../../components/Backbutton';


const NewsFull = ({}) => {
  const { tag } = useParams();
  const [newsData, setnewsData] = useState([]);
  const selectedNews = newsData.find(news => news.tag === tag);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await readNews();
        setnewsData(response);
      } catch (error) {
        console.error('Error fetching blog data:', error);
      }
    };
    fetchData();
  }, [tag]);


  return (
    <div>
    {selectedNews && (
        <div key={selectedNews._id} className='bg-[#131313f8]'>
          <div className='flex justify-center'>
            <div className='p-5 xl:w-[1100px] lg:w-[900px] md:w-[800px] sm:w-[600px] w-[450px]'>
              <BackButton />

              <h1 className='mt-4 text-[#EEDD82] font-IBM lg:text-[35px] text-[27px]'>
                {selectedNews.title1}
              </h1>

              <div className='w-full h-[0.04px] bg-[#c3c3c3] mt-4' />

              <h1 className='mt-4 text-[#EEDD82] font-IBM lg:text-[35px] text-[27px]'>
                {selectedNews.title2}
              </h1>

              <div className='md:mt-10 mt-7 flex justify-center'>
                <img
                  src={selectedNews.imageUrl}
                  alt="Blog Image"
                  className='xl:w-[850px] lg:w-[700px] md:w-[600px] xl:h-[450px] lg:h-[350px]
                   md:h-[300px] w-[500px] h-[250px] object-cover'
                />
              </div>

              <div className='md:mt-10 mt-5'>
                <p className='lg:pl-[30px] md:mx-[50px] font-Athiti'>
                <div dangerouslySetInnerHTML={{ __html: selectedNews.detail1 }} />
                </p>
              </div>      

            </div>
          </div>
        </div>
    )}
    
    </div>
  );
}

export default NewsFull;


