import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { readGame } from '../../store/api/frontend'

const Gamecard = () => {
    const [selectedMenu, setSelectedMenu] = useState('link');
    const [GameData, setGameData] = useState([]);

    const handleMenuClick = (menu) => {
        setSelectedMenu(menu);
    };
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await readGame();
                setGameData(response);
            } catch (error) {
                console.error('Error fetching blog data:', error);
            }
        };
        fetchData();
    }, []);

    return (
        <div>
            {GameData.map((game) => (
                <div key={game._id} className='hidden md:block '>
                    <div className='bg-gradient-to-r from-[#1a1a29]  to-[#0d101b] w-full xl:h-[350px]  lg:h-[280px] md:h-[220px] h-[200px]'>
                        <div className='grid grid-cols-2'>
                            {/* Grid 1 (40%) */}
                            <div className='col-span-1'>

                                {selectedMenu === 'link' && (
                                    <div className='mx-[80px] lg:mt-[20px] mt-1'>
                                        <div className='flex justify-end'>
                                            <h1 className='text-[#EEDD82] xl:text-[35px] lg:text-[30px] md:text-[25px] font-IBM text-right'>
                                                {game.title}
                                            </h1>
                                        </div>
                                        <div className='flex justify-end mt-[-15px]'>
                                            <h2 className='text-white lg:text-[17px] md:text-[14px] font-Athiti text-right'>
                                                {game.subtitle}
                                            </h2>
                                        </div>
                                        <div className='flex justify-end lg:mt-4'>
                                            <p className='text-white xl:text-[20px] lg:text-[15px] md:text-[10px] text-[14px] font-Athiti text-right
                                lg:max-w-[35ch]'>
                                                {game.detail}
                                            </p>
                                        </div>
                                        <div className='flex justify-end mt-2'>
                                            <p className='text-[#ff4545] xl:text-[18px] md:text-[9px] text-[14px] font-Kanit2 text-right
                                lg:max-w-[30ch]'>
                                                {game.redtext}
                                            </p>
                                        </div>
                                        <div className='flex justify-end mt-2'>
                                            <Link to={`/menu1/${game.link1}`}>
                                                <button className='text-[#EEDD82] lg:text-[15px] md:text-[12px] font-Athiti cursor-pointer'
                                                >
                                                    อ่านเพิ่มเติม
                                                </button>
                                            </Link>
                                        </div>
                                    </div>
                                )}

                                {selectedMenu === 'sport' && (
                                    <div className=' mx-[80px] lg:mt-[20px] mt-1'>
                                        <div className='flex justify-end'>
                                            <h1 className='text-[#EEDD82] xl:text-[35px] lg:text-[30px] md:text-[25px] font-IBM text-right'>
                                                {game.title2}
                                            </h1>
                                        </div>
                                        <div className='flex justify-end mt-[-15px]'>
                                            <h2 className='text-white lg:text-[17px] md:text-[14px] font-Athiti text-right'>
                                                {game.subtitle2}
                                            </h2>
                                        </div>
                                        <div className='flex justify-end'>
                                            <p className='text-white xl:text-[20px] lg:text-[15px] md:text-[10px] text-[14px] font-Athiti text-right
                                lg:max-w-[35ch]'>
                                                {game.detail2}
                                            </p>
                                        </div>
                                        <div className='flex justify-end mt-2'>
                                            <p className='text-[#ff4545] xl:text-[18px] md:text-[9px] text-[14px] font-Kanit2 text-right
                                lg:max-w-[30ch]'>
                                                {game.redtext2}
                                            </p>
                                        </div>
                                        <div className='flex justify-end mt-2'>
                                            <Link to={`/menu2/${game.link2}`}>
                                                <button className='text-[#EEDD82] lg:text-[15px] md:text-[12px] font-Athiti cursor-pointer'
                                                >
                                                    อ่านเพิ่มเติม
                                                </button>
                                            </Link>
                                        </div>
                                    </div>
                                )}

                                {selectedMenu === 'casino' && (
                                    <div className=' mx-[80px] lg:mt-[20px] mt-1'>
                                        <div className='flex justify-end '>
                                            <h1 className='text-[#EEDD82] xl:text-[35px] lg:text-[30px] md:text-[25px] font-IBM'>
                                                {game.title3}
                                            </h1>
                                        </div>
                                        <div className='flex justify-end mt-[-15px]'>
                                            <h2 className='text-white lg:text-[17px] md:text-[14px] font-Athiti text-right'>
                                                {game.subtitle3}
                                            </h2>
                                        </div>
                                        <div className='flex justify-end'>
                                            <p className='text-white xl:text-[20px] lg:text-[15px] md:text-[10px] text-[14px] font-Athiti text-right
                                lg:max-w-[35ch]'>
                                                {game.detail3}
                                            </p>
                                        </div>
                                        <div className='flex justify-end mt-2'>
                                            <p className='text-[#ff4545] xl:text-[18px] md:text-[9px] text-[14px] font-Kanit2 text-right
                                lg:max-w-[30ch]'>
                                                {game.redtext3}
                                            </p>
                                        </div>

                                        <div className='flex justify-end mt-2'>
                                            <Link to={`/menu3/${game.link3}`}>
                                                <button className='text-[#EEDD82] lg:text-[15px] md:text-[12px] font-Athiti cursor-pointer'
                                                >
                                                    อ่านเพิ่มเติม
                                                </button>
                                            </Link>
                                        </div>
                                    </div>
                                )}

                                {selectedMenu === 'slot' && (
                                    <div className=' mx-[80px] lg:mt-[20px] mt-1'>
                                        <div className='flex justify-end'>
                                            <h1 className='text-[#EEDD82] xl:text-[35px] lg:text-[30px] md:text-[25px] font-IBM text-right'>
                                                {game.title4}
                                            </h1>
                                        </div>
                                        <div className='flex justify-end mt-[-15px]'>
                                            <h2 className='text-white lg:text-[17px] md:text-[14px] font-Athiti text-right'>
                                                {game.subtitle4}
                                            </h2>
                                        </div>
                                        <div className='flex justify-end'>
                                            <p className='text-white xl:text-[20px] lg:text-[15px] md:text-[10px] text-[14px] font-Athiti text-right
                                lg:max-w-[35ch]'>
                                                {game.detail4}
                                            </p>
                                        </div>
                                        <div className='flex justify-end mt-2'>
                                            <p className='text-[#ff4545] xl:text-[18px] md:text-[9px] text-[14px] font-Kanit2 text-right
                                lg:max-w-[30ch]'>
                                                {game.redtext4}
                                            </p>
                                        </div>

                                        <div className='flex justify-end mt-2'>
                                            <Link to={`/menu4/${game.link4}`}>
                                                <button className='text-[#EEDD82] lg:text-[15px] md:text-[12px] font-Athiti cursor-pointer'
                                                >
                                                    อ่านเพิ่มเติม
                                                </button>
                                            </Link>
                                        </div>
                                    </div>
                                )}
                            </div>

                            {/* Grid 2 (60%) */}
                            <div className='overflow-hidden'>
                                <div className='lg:w-[500px] md:w-[600px] w-[400px] flex mx-4 lg:mx-5'>
                                    <img src={game.imageUrl}
                                        className='2xl:w-[250px] xl:w-[185px] lg:w-[140px]
                            md:w-[115px] xl:h-[300px] md:h-[170px] lg:h-[230px] transform'
                                    />
                                    <img src={game.imageUrl1}
                                        className='2xl:w-[250px] xl:w-[185px] lg:w-[140px]
                            md:w-[115px] xl:h-[300px] md:h-[170px] lg:h-[230px] transform'
                                    />
                                    <img src={game.imageUrl2}
                                        className='2xl:w-[250px] xl:w-[185px] lg:w-[140px]
                            md:w-[115px] xl:h-[300px] md:h-[170px] lg:h-[230px] transform'
                                    />
                                    <img src={game.imageUrl3}
                                        className='2xl:w-[250px] xl:w-[185px] lg:w-[140px]
                            md:w-[115px] xl:h-[300px] md:h-[170px] lg:h-[230px] transform'
                                    />
                                </div>

                                <div className='2xl:w-[820px] xl:w-[730px] lg:w-[570px] md:w-[460px] lg:mx-[15px] md:mx-[10px] flex'>
                                    <button
                                        type='button'
                                        className={`bg-gradient-to-l from-[#080808ee]  to-[#242424d0] text-white 
                                    xl:w-[700px] lg:w-[545px] md:w-[120px] h-[50px] lg:h-[50px] hover:bg-[#EEDD82]
                                    transform -skew-x-12 ${selectedMenu === 'link' && 'bg-gray-900'}`}
                                        onClick={() => handleMenuClick('link')}
                                    >
                                        {game.menu1}
                                    </button>
                                    <button
                                        type='button'
                                        className={`bg-gradient-to-l from-[#080808ee]  to-[#242424d0] text-white 
                                    xl:w-[700px] lg:w-[545px] md:w-[120px]  h-[50px] lg:h-[50px] hover:bg-[#EEDD82]
                                    transform -skew-x-12  ${selectedMenu === 'sport' && 'bg-gray-900'}`}
                                        onClick={() => handleMenuClick('sport')}
                                    >
                                        {game.menu2}
                                    </button>
                                    <button
                                        type='button'
                                        className={`bg-gradient-to-l from-[#080808ee]  to-[#242424d0] text-white 
                                    xl:w-[700px] lg:w-[545px] md:w-[120px] h-[50px] lg:h-[50px] hover:bg-[#EEDD82]
                                    transform -skew-x-12  ${selectedMenu === 'casino' && 'bg-gray-900'}`}
                                        onClick={() => handleMenuClick('casino')}
                                    >
                                        {game.menu3}
                                    </button>
                                    <button
                                        type='button'
                                        className={`bg-gradient-to-l from-[#080808ee]  to-[#242424d0] text-white 
                                    xl:w-[700px] lg:w-[545px] md:w-[120px] h-[50px] lg:h-[50px] hover:bg-[#EEDD82]
                                    transform -skew-x-12  ${selectedMenu === 'slot' && 'bg-gray-900'}`}
                                        onClick={() => handleMenuClick('slot')}
                                    >
                                        {game.menu4}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    )
}

export default Gamecard

