import { http } from '../../utils';
import { URL_API } from '../../utils/local'

export const readBlog = async () => {
    try {
      const response = await http.get('/blog/get');
      const data = response.data.map((blog) => ({
        ...blog,
        imageUrl: blog.file ? `${URL_API}/${blog.file.replace('image/', '')}` : null,
      }));
      
      return data;
  
    } catch (error) {
      console.log('api error', error)
      throw error;
    }
  };

