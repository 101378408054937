import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';


import Layout from './page/layout';
import Home from './page/Home';
import Blog from './page/Blog';
import BlogsModels from './page/blogs'
import Menu from './page/about/Menu';
import Menu1 from './page/about/Menu1';
import Menu2 from './page/about/Menu2';
import Menu3 from './page/about/Menu3';
import Menu4 from './page/about/Menu4';
import AboutUs from './page/AboutUs';
import NewFull from './components/news/NewsFull';

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route  element={<Layout />}>
          <Route path="/" element={<Home />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:tag" element={<BlogsModels />} />

          
          <Route path="/menu1/:link1" element={<Menu />} />
          <Route path="/menu2/:link2" element={<Menu2 />} />
          <Route path="/menu4/:link4" element={<Menu3 />} />
          <Route path="/menu3/:link3" element={<Menu4 />} />

          <Route path="/detail/:link" element={<Menu1 />} />
          <Route path="/news/:tag" element={<NewFull />} />
          <Route path="/about-us" element={<AboutUs />} />

          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
