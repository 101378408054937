// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 15px;
  font-size: 25px;
  margin-bottom: 10px;

}

p{
  font-size: 14px;
}`, "",{"version":3,"sources":["webpack://./src/utils/style.css"],"names":[],"mappings":"AAAA;;;;;;EAME,gBAAgB;EAChB,eAAe;EACf,mBAAmB;;AAErB;;AAEA;EACE,eAAe;AACjB","sourcesContent":["h1,\nh2,\nh3,\nh4,\nh5,\nh6 {\n  margin-top: 15px;\n  font-size: 25px;\n  margin-bottom: 10px;\n\n}\n\np{\n  font-size: 14px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
