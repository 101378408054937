import { useState, useEffect } from "react"
import { Link, useLocation } from "react-router-dom"
import { styles } from '../page/layout/style';
import menu from "../assets/menu.svg"
import close from "../assets/close.svg"
import { readTitle, readNav, readLink } from "../store/api/frontend";



const Navbar = () => {
  const [active, setActive] = useState(``);
  const [toggle, setToggle] = useState(false);
  const location = useLocation();
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [data3, setData3] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await readTitle();
        setData(response);
      } catch (error) {
        console.error('Error fetching img data:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await readLink();
        if (Array.isArray(response.data)) {
          setData2(response.data);
        } else {
          console.error('Response data is not an array:', response.data);
        }
      } catch (error) {
        console.error('Error fetching nav data:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await readNav();
        console.log(response)
        setData(response);
      } catch (error) {
        console.error('Error fetching img data:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    setToggle(false);
  }, [location.pathname]);


  return (
    <nav
      className={`${styles.paddingX} navbar w-full flex items-center lg:py-5 py-5  z-20`}
    >
      {data.map((nav) => (
        <div key={nav._id} className="w-full flex justify-between items-center max-w-7xl mx-auto">
          <Link to="/"
            className="flex items-center gap-2"
            onClick={() => {
              setActive("");
              window.scrollTo(0, 0)
            }}
          >
            <img src={nav.imageUrl} className="w-[125px] h-[50px] " />
          </Link>
          {data2.map((link) =>
            <ul key={link._id} className="list-non hidden md:flex flex-row gap-5 ">
              <div className="mx-[-10px]">
                <Link to={link.login}>
                  <button className="login-mobile lg:w-[140px] lg:h-[45px] w-[120px] h-[40px] rounded-md text-black
            text-[16px] font-IBM">
                    เข้าสู่ระบบ
                  </button>
                </Link>
              </div>
              <div className="mx-1">
                <Link to={link.register}>
                  <button className="top-pc lg:w-[140px] lg:h-[45px] w-[120px] h-[40px] rounded-md text-[#f1e381]
            text-[16px] font-IBM">
                    สมัครสมาชิก
                  </button>
                </Link>
              </div>
            </ul>
          )}

          <div className="md:hidden flex flex-1 justify-end items-center   ">
            {data3.map((link) =>
              <div key={link._id} className="mx-4">
                <Link to={link.login}>

                  <button className="login-mobile w-[100px] h-[30px] rounded-lg text-gray-800
            text-[15px] font-IBM">
                    เข้าสู่ระบบ
                  </button>
                </Link>

              </div>
            )}

            <img
              src={toggle ? close : menu}
              alt="menu"
              className="w-[28px] h-[28px] object-contain cursor-pointer"
              onClick={() => setToggle(!toggle)}
            />
            <div className={`${!toggle ? 'hidden' : 'flex'} p-6 bg-[#000000f0] 
            absolute top-20 right-0 md:mx-8 mx-8 min-w-[140px] z-10 rounded-xl`}
            >
              <ul className="list-non flex justify-end items-start flex-col gap-4">
                {data2.map((link, index) => (
                  <li
                    key={index}
                    className="grid grid-cols-1 gap-3"
                  >
                    <Link to={`${link.link1}`} className="font-Kanit2 mx-2 hover:text-[#fcf6c3]">
                      {link.menu1}
                    </Link>
                    <Link to={`${link.link2}`} className="font-Kanit2 mx-2 hover:text-[#fcf6c3]">
                      {link.menu2}
                    </Link>
                    <Link to={`${link.link3}`} className="font-Kanit2 mx-2 hover:text-[#fcf6c3]">
                      {link.menu3}
                    </Link>
                    <Link to={`${link.link4}`} className="font-Kanit2 mx-2 hover:text-[#fcf6c3]">
                      {link.menu4}
                    </Link>
                    <Link to={`${link.link5}`} className="font-Kanit2 mx-2 hover:text-[#fcf6c3]">
                      {link.menu5}
                    </Link>
                    <Link to={`${link.link6}`} className="font-Kanit2 mx-2 hover:text-[#fcf6c3]">
                      {link.menu6}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      ))}
    </nav>
  )
}

export default Navbar